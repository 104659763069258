/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Hero } from '../components/Hero'
import Layout from '../components/Layout'
import CTASection from '../components/CTASection'
import { useStaticQuery, graphql } from 'gatsby'
import theme from '../theme'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'
import { Feature } from '../components/Feature'
import Block from '../components/Block'
import styled from 'styled-components'
import Customers from '../components/CustomerLogos'
import Page from '../components/Page'
import { LeadHeading } from '../components/LeadHeading'
import ThreeColumns from '../components/ThreeColumns'
import MainDemoRequestCTA from '../components/MainDemoRequestCTA'
import { FeaturedBlogArticles } from '../components/changelog/FeaturedBlogArticles'
import MetaImage from '../assets/images/git-analytics-meta.png'
//9 feature icons
import ReviewTime from '../assets/images/icons/clock.svg'
import WIPLimits from '../assets/images/icons/checklist.svg'
import ReviewRate from '../assets/images/icons/percentage.svg'
import InvestmentInsights from '../assets/images/icons/barchart.svg'
import BatchSize from '../assets/images/icons/size.svg'
import CycleTime from '../assets/images/icons/cycle.svg'
import CIInsights from '../assets/images/icons/robot-hand.svg'
import BugInsights from '../assets/images/icons/castle.svg'
import DeploymentFrequency from '../assets/images/icons/rocket.svg'
//Explore next
import { ReadNext } from '../components/ReadNext'
import continuousIcon from '../assets/images/icons/ways.svg'
import QuoteBlock from '../components/blocks/QuoteBlock'

const SwarmiaForDevsPage = () => {
  const developerImages = useStaticQuery(graphql`
    query {
      feature1: file(
        relativePath: { eq: "images/engineering-metrics/feature-health.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature2: file(
        relativePath: {
          eq: "images/engineering-metrics/feature-flow-insights.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature3: file(
        relativePath: {
          eq: "images/for-developers/feature-dev-notifications.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      glance1: file(
        relativePath: { eq: "images/for-developers/plug-in-tools.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      glance2: file(
        relativePath: { eq: "images/for-developers/get-actionable-alerts.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
            height: 300
          )
        }
      }
      glance3: file(
        relativePath: {
          eq: "images/engineering-metrics/glance-no-harmful-metrics.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 420
          )
        }
      }
      feature4: file(
        relativePath: { eq: "images/for-developers/digest-big.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
      feature5: file(
        relativePath: { eq: "images/for-developers/feature-review-wa-big.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            quality: 100
            placeholder: BLURRED
            width: 716
          )
        }
      }
    }
  `)
  return (
    <Layout
      title="The healthy alternative to Git analytics"
      description="Forget about toxic Git statistics. Instead, focus on improving software development productivity with a balanced set of metrics."
      metaImage={MetaImage}
    >
      <Hero
        background={
          <StaticImage
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: -1,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
            src="../assets/images/backgrounds/git-analytics.png"
            aria-hidden="true"
            alt=""
            quality={90}
          />
        }
      >
        <Block type="padding-medium">
          <h1>The healthy alternative to traditional Git analytics</h1>
          <div className="hero-description">
            <p className="large_text">
              Forget about toxic Git statistics. Instead, focus on improving
              software development productivity with a balanced set of metrics.
            </p>
          </div>
          <MainDemoRequestCTA />
        </Block>
      </Hero>
      <Page type="padding-bottom-small padding-top-none layout-index">
        <Customers />
        <Block type="padding-top-small padding-bottom-medium">
          <LeadHeading title="Git analytics, the Swarmia way" />
          <ThreeColumns>
            <div className="columns columns-reverse three-columns">
              <div className="column">
                <h4>
                  Connect the dots between your version control and issue
                  tracker
                </h4>
                <div>
                  <GatsbyImage
                    image={
                      developerImages.glance1.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  Adopt healthy, research-backed habits and track progress
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance2.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="column">
                <h4>
                  No harmful leaderboards or data on individual performance
                </h4>
                <div className="image-wrapper">
                  <GatsbyImage
                    image={
                      developerImages.glance3.childImageSharp.gatsbyImageData
                    }
                    alt=""
                  />
                </div>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <Block size="large" type="padding-bottom-large padding-top-small">
          <LeadHeading
            title="Much more than a Git dashboard"
            description="Swarmia is not just a static Git dashboard. In addition to useful engineering metrics, it also gives teams the tools they need to improve developer wellbeing and team productivity."
          />
          <div className="features full-width-images" id="features">
            <Feature
              type="feature feature-grid-right"
              heading="Drive positive change with Working Agreements"
              image={developerImages.feature1.childImageSharp.gatsbyImageData}
              imageAlt="Swarmia's working agreements"
            >
              <p>
                The big problem with most Git analysis tools is that they don’t
                help teams to make concrete improvements.
              </p>
              <p>
                Working Agreements in Swarmia, on the other hand, encourage your
                team to adopt healthy habits to limit work-in-progress, increase
                collaboration, prioritize code reviews, and more.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-left"
              heading="Get holistic insights from Git and your issue tracker"
              image={developerImages.feature2.childImageSharp.gatsbyImageData}
              imageAlt="Flow insights in Swarmia"
            >
              <p>
                Instead of only surfacing simple Git repository statistics like
                commits and PRs, Swarmia combines useful Git-based metrics with
                data from your issue tracker.
              </p>
              <p>
                This way, you’ll be able to connect the dots between planned
                work with the work that has actually happened across your repos.
              </p>
            </Feature>
            <Feature
              type="feature feature-grid-right"
              heading="Improve your team’s workflows"
              image={developerImages.feature3.childImageSharp.gatsbyImageData}
              imageAlt="Swarmia's Slack notifications"
            >
              <p>
                Bookmark the pull request view in Swarmia to get easy access to
                all the open PRs in your team — regardless of the repo they’re
                in.
              </p>
              <p>
                You can also turn on handy notifications that allow you to
                quickly react and reply to comments in GitHub without ever
                leaving Slack.
              </p>
            </Feature>
          </div>
        </Block>
        <QuoteBlock person="svyat" />

        <FeaturesGridWrapper>
          <Block size="large" type="padding-large">
            <div className="lead-heading">
              <h2>It’s hard to improve what you don’t measure</h2>
              <p>
                Here are some of the engineering metrics you’ll see in Swarmia.
              </p>
            </div>
            <FeaturesGrid>
              <div>
                <img src={CycleTime} alt="Cycle time" />
                <h4>Cycle time</h4>
                <p>
                  Understand how long it takes for your team to complete pull
                  requests, tasks, stories, and epics.
                </p>
              </div>
              <div>
                <img src={DeploymentFrequency} alt="Deployment frequency" />
                <h4>Deployment frequency</h4>
                <p>
                  Keep track of how often you’re deploying — and how often the
                  deployments are failing.
                </p>
              </div>
              <div>
                <img src={InvestmentInsights} alt="Investment distribution" />
                <h4>Investment distribution</h4>
                <p>
                  Know what your engineering teams are working on and establish
                  the right balance between roadmap work and other work.
                </p>
              </div>
              <div>
                <img src={BugInsights} alt="Bug Insights" />
                <h4>Bug Insights</h4>
                <p>
                  Stay on top of the bugs your team is working on and the time
                  it takes to fix them.
                </p>
              </div>
              <div>
                <img src={BatchSize} alt="Batch size" />
                <h4>Batch size</h4>
                <p>
                  Keep your batch size small to roll out features to customers
                  faster and with less risk.
                </p>
              </div>
              <div>
                <img src={CIInsights} alt="CI insights" />
                <h4>CI insights</h4>
                <p>
                  Spot flaky tests and improve the performance of your CI
                  pipeline.
                </p>
              </div>
              <div>
                <img src={ReviewTime} alt="Review time" />
                <h4>Review time</h4>
                <p>
                  Identify review bottlenecks and get your work merged faster.
                </p>
              </div>
              <div>
                <img src={ReviewRate} alt="Review rate" />
                <h4>Review rate</h4>
                <p>
                  Maintain high code quality by maximizing the percentage of
                  code that gets reviewed before it’s merged.
                </p>
              </div>
              <div>
                <img src={WIPLimits} alt="WIP limits" />
                <h4>WIP limits</h4>
                <p>
                  Allow teams to set their own work in progress limits to boost
                  focus and productivity.
                </p>
              </div>
            </FeaturesGrid>
          </Block>
        </FeaturesGridWrapper>

        <Block type="padding-top-small padding-bottom-large">
          <LeadHeading
            title="The Git metrics we don’t track"
            description="Just because you can measure a certain metric doesn’t mean you should. We’ve consciously left out some unhealthy Git metrics that have a tendency to do more harm than good."
          />
          <ThreeColumns>
            <div className="columns three-columns">
              <div className="column">
                <h4>Developer stack ranking</h4>
                <p>
                  Swarmia focuses on improving development productivity on the
                  team and organization level. That’s why you won’t find Git
                  commit leaderboards or other harmful activity metrics in
                  Swarmia.
                </p>
              </div>
              <div className="column">
                <h4>Lines of code (LOC)</h4>
                <p>
                  Lines of code is a terrible way to measure engineering
                  productivity. In fact, Swarmia only records lines of code in
                  the context of batch size and code review notifications.
                </p>
              </div>
              <div className="column">
                <h4>Code churn and rework</h4>
                <p>
                  We believe that rewriting your own code is a perfectly
                  acceptable way to solve complex problems. That’s why you won’t
                  be able to track code churn or rework with Swarmia.
                </p>
              </div>
            </div>
          </ThreeColumns>
        </Block>
        <ReadNext
          label="Explore next"
          heading="Working Agreements"
          icon={continuousIcon}
          url="/product/working-agreements/"
        >
          <p className="large_text">
            Tracking the right metrics is just the first step. To make sure your
            insights don&#39;t just sit on a dashboard, Swarmia allows your
            teams to set their own Working Agreements.
          </p>
        </ReadNext>
      </Page>
      <CTASection>
        Swarmia is how the best teams get better. Get started with a demo or
        a 14-day trial.
      </CTASection>
      <FeaturedBlogArticles
        articles={[
          '/blog/git-analytics/',
          '/blog/measuring-software-development-productivity/',
          '/blog/dora-metrics/',
        ]}
        heading="Learn more from our blog"
      />
    </Layout>
  )
}

export default SwarmiaForDevsPage

const FeaturesGridWrapper = styled.div`
  border-top: 1px rgba(0, 0, 0, 0.05) solid;
  text-align: center;
  p {
    margin-top: 0.75rem;
  }
`

const FeaturesGrid = styled.div`
  margin: 0 auto;
  display: grid;
  grid-gap: 2rem 3rem;
  h3 {
    margin-top: 1.2rem;
  }
  h4 {
    margin-top: 1rem;
  }
  img {
    width: 64px;
    height: 64px;
  }
  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem 4rem;
  }
  @media screen and (min-width: ${theme.breakpoints['large']}) {
    grid-template-columns: repeat(3, 1fr);
  }
`
